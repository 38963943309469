import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import { LangProvider } from './src/context/LangContext'
import { ScrollProvider } from './src/context/ScrollContext'

export const wrapRootElement = ({ element }) => (
  <LangProvider>
    <ScrollProvider>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
      >
        {element}
      </GoogleReCaptchaProvider>
    </ScrollProvider>
  </LangProvider>
)
