import React, { useEffect, useState } from 'react'

import { useScrollYPosition } from '../hooks/useScrollPosition'

const ScrollContext = React.createContext()

export const ScrollProvider = props => {
  const [scroll, setScroll] = useState()
  const scrollY = useScrollYPosition()
  useEffect(() => {
    setScroll(scrollY)
  }, [scrollY])

  return (
    <ScrollContext.Provider
      value={{
        scroll,
      }}
    >
      {props.children}
    </ScrollContext.Provider>
  )
}

export default ScrollContext
