import React, { useState } from 'react'

const LangContext = React.createContext()

export const LangProvider = props => {
  const [lang, setLang] = useState()

  return (
    <LangContext.Provider
      value={{
        lang,
        setLang: x => setLang(x),
      }}
    >
      {props.children}
    </LangContext.Provider>
  )
}

export default LangContext
