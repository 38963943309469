import { useEffect, useState } from 'react'

const isBrowser = typeof window !== `undefined`

const getScrollPosition = () => {
  return isBrowser
    ? { x: window.pageXOffset, y: window.pageYOffset }
    : { x: 0, y: 0 }
}

export const useScrollPosition = () => {
  const [position, setScrollPosition] = useState(getScrollPosition())

  useEffect(() => {
    let requestRunning = false
    const handleScroll = () => {
      if (!requestRunning) {
        window.requestAnimationFrame(() => {
          setScrollPosition(getScrollPosition())
          requestRunning = false
        })
        requestRunning = true
      }
    }

    document.addEventListener('scroll', handleScroll, {
      passive: true,
    })
    window.addEventListener('load', handleScroll())
    return () => {
      document.removeEventListener('scroll', handleScroll, {
        passive: true,
      })
      window.removeEventListener('load', handleScroll())
    }
  }, [])

  return position
}

export const useScrollXPosition = () => {
  const { x } = useScrollPosition()
  return x
}

export const useScrollYPosition = () => {
  const { y } = useScrollPosition()
  return y
}
